import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTING, STR_ACCOUNTS_PAYABLE, STR_ACCOUNTS_RECEIVABLE, STR_ADD_NEW, STR_AVAILABLE_STOCK, STR_BALANCE_SHEET, STR_CAPITAL, STR_CASH_FLOW_STATEMENT, STR_CLIENTS, STR_DASHBOARD, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_FIXED_ASSETS, STR_INCOME_STATEMENT, STR_INVESTMENT, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_OPERATION_COSTS, STR_OPERATION_COSTS_TODAY, STR_PRODUCTION, STR_PURCHASES, STR_SALES, STR_SALES_TODAY, STR_STOCK, STR_STOCKLIST, STR_TODOS, STR_WATEJA, STR_WORKING_CAPITAL } from "../Strings";
import { MdMoney, MdSearch } from "react-icons/md";
import OneRecord from '../ones/OneRecord';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    //..
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])


  if (ready) {
    return (
      <div
        className="container"

      >
        <h1 className="mSectionTitle mNoMargin">{STR_ACCOUNTING[appContext.language]}</h1>

        <div className="row">

          <div className="col-12 col-md-12 mb-3 mt-3">
            <div className="row mt-4">

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'capital',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_CAPITAL[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'purchases',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_PURCHASES[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'production',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_PRODUCTION[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'receivables',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_ACCOUNTS_RECEIVABLE[appContext.language]}
                </button>
              </div>


              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'payables',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_ACCOUNTS_PAYABLE[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'investment',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_INVESTMENT[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'sales',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_SALES[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'stock',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_STOCK[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'operation_costs',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_OPERATION_COSTS[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'cash_flow',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_CASH_FLOW_STATEMENT[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'income_statement',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_INCOME_STATEMENT[appContext.language]}
                </button>
              </div>

              <div className="col-4 col-md-3 mb-2 mt-2">
                <button
                  className="btn btn-sm btn-block"
                  onClick={() => {
                    appContext.navTo({
                      item: 'accounting',
                      subItem: 'balance_sheet',
                    })
                  }}
                  style={{
                    height: "100%",
                  }}
                >
                  <MdMoney size={30} /><br />
                  {STR_BALANCE_SHEET[appContext.language]}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}