import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_CLIENTS, STR_DASHBOARD, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_TODOS, STR_WATEJA } from "../Strings";
import { MdSearch } from "react-icons/md";
import OneCustomer from '../ones/OneCustomer';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");
  const [category, setCategory] = useState("all");
  const [customersList, setCustomersList] = useState([])
  const [clientsList, setClientsList] = useState([])
  const [leadsList, setLeadsList] = useState([])

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getMyCustomers();
    setReady(true);
  }

  function groupCustomers() {
    if (appContext.myCustomers) {
      const _clients = [];
      const _leads = [];

      for (let _customer of appContext.myCustomers) {
        if (_customer.customerLevel === 'lead') {
          _leads.push(_customer)
        } else if (_customer.customerLevel === 'client') {
          _clients.push(_customer)
        }
      }

      setClientsList(_clients);
      setLeadsList(_leads);

      if (category === 'all') {
        setCustomersList(appContext.myCustomers);
      } else if (category === 'leads') {
        setCustomersList(_leads);
      } else if (category === 'clients') {
        setCustomersList(_clients);
      }
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    groupCustomers();
  }, [appContext.myCustomers, category])

  if (ready) {
    return (
      <div
        className="container"

      >
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mt-3">
            <div className="card" style={{ height: "100%" }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6
                    className="mSectionTitle mNoMargin"
                  >
                    {STR_MY_CUSTOMERS[appContext.language]}
                  </h6>

                  <button
                    className="btn btn-secondary btn-sm btn-rounded"
                    style={{
                      flexShrink: "0",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: "view",
                        subItem: "register-customer",
                      })
                    }}
                  >
                    {STR_ADD_NEW[appContext.language]}
                  </button>
                </div>

                <h1
                  style={{
                    fontSize: "48px",
                    marginTop: "40px",
                  }}
                  className="text-secondary font-bold"
                >
                  {formatMoney(appContext.myCustomers?.length)}
                </h1>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 mb-3 mt-3">
            <div
              className={(category === 'clients') ? 'card bg-secondary' : 'card'}
              style={{
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => setCategory('clients')}
            >
              <div className="card-body">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_CLIENTS[appContext.language]}
                </h6>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "20px",
                  }}
                >
                  {formatMoney(clientsList?.length)}
                </h1>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 mb-3 mt-3">
            <div
              className={(category === 'leads') ? 'card bg-secondary' : 'card'}
              style={{
                height: "100%",
                cursor: "pointer",
              }}
              onClick={() => setCategory('leads')}
            >
              <div className="card-body">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_LEADS[appContext.language]}
                </h6>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "20px",
                  }}
                >
                  {formatMoney(leadsList?.length)}
                </h1>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 mb-3 mt-3">


            <div className="row mb-4">
              <div
                className="col-md-3"
              >
                <div
                  className="input-group"
                >
                  <span
                    className="input-group-text font-semi-bold text-secondary"
                    style={{
                      fontSize: "22px"
                    }}
                  >
                    <MdSearch size={24} />
                  </span>
                  <input
                    className="form-control"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    placeholder={STR_FILTER_BY_NAME_OR_PHONE[appContext.language]}
                  />
                </div>
              </div>


            </div>

            {
              (customersList && customersList.length > 0) ?
                customersList.map((item, i) => {
                  if (filter && filter.trim().length > 0) {
                    if (
                      String(item.phone).toUpperCase().indexOf(String(filter).toUpperCase()) > -1 ||
                      String(item.name).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                    ) {
                      return (
                        <OneCustomer data={item} key={i} />
                      )
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <OneCustomer data={item} key={i} />
                    )
                  }

                })
                : <h6 style={{ fontSize: "14px", }}>{STR_NO_RECORDS[appContext.language]}</h6>
            }

          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}