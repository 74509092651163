import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import MoreMenu from "../views/MoreMenu";
import RegisterBusiness from "../views/RegisterBusiness";
import Users from '../views/Users';
import Business from '../views/Business';

export default function More(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if (appContext.doesUserHasBusiness()) {
      if (
        !appContext.navSubItem &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <MoreMenu />
      } else if (
        appContext.navSubItem === 'users' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Users />
      } else if (
        appContext.navSubItem === 'business' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Business />
      }
    } else {
      return <RegisterBusiness />
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem])

  //return desired view
  return view;
}