import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_EDIT, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [followupLog, setFollowupLog] = useState(null);

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-customer-data", { uid: id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }


  useEffect(() => {
    init();
  }, [id])

  useEffect(() => {
    if (data) {
      setFollowupLog(JSON.parse(data.followupLog));
    }
  }, [data])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >

        <CustomerWidget data={data} />

        <div className="row">
          <div className="col-md-12">
            {
              (appContext.systemParams.customerFollowupSteps) ?
                JSON.parse(appContext.systemParams.customerFollowupSteps).map((item, i) => {
                  return (
                    <div
                      className="mb-4 mt-4"
                      style={{
                        width: "100%",
                        padding: "0",
                      }}
                      key={i}
                    >
                      <h6
                        style={{
                          fontSize: "24px",
                        }}
                        className="font-bold"
                      >
                        {item.id}
                        <span
                          style={{
                            fontSize: "20px",
                            display: "block",
                          }}
                          className="font-light"
                        >
                          {item.description[appContext.language]}
                        </span>
                      </h6>

                      {
                        (followupLog && followupLog[item.id]) ?
                          <div
                            style={{
                              width: "100%",
                              padding: "10px",
                              borderLeft: "10px solid var(--secondaryColor)",
                            }}
                          >
                            {
                              followupLog[item.id].map((fItem, fI) => {
                                return (
                                  <div
                                    style={{
                                      width: "100%",

                                    }}
                                    key={fI}
                                  >
                                    <div className="text-end">
                                      <span
                                        className="badge bg-success"
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      >
                                        {formatDate(fItem.timestamp).substring(0, 10)}
                                      </span>
                                    </div>

                                    <div className="text-start">
                                      <h6
                                        style={{
                                          fontSize: "14px",
                                        }}
                                        className="font-regular"
                                      >
                                        {fItem.description}
                                      </h6>

                                      {
                                        (fItem.promiseMade === 'yes') ?
                                          <div className="mt-2">
                                            <h6
                                              style={{
                                                fontSize: "14px",
                                              }}
                                              className="font-regular"
                                            >
                                              <span className="text-muted font-bold">{STR_PROMISE[appContext.language]}: &nbsp;&nbsp;</span>
                                              {fItem.promiseDescription}
                                            </h6>

                                            <h6
                                              style={{
                                                fontSize: "14px",
                                              }}
                                              className="font-regular"
                                            >
                                              <span className="text-muted font-bold">{STR_PROMISE_DUE_DATE[appContext.language]}: &nbsp;&nbsp;</span>
                                              {formatDate(fItem.promiseDueDate).substring(0, 10)}
                                            </h6>
                                          </div>
                                          : ""
                                      }
                                    </div>
                                    <hr />
                                  </div>
                                )
                              })
                            }
                          </div>
                          : ""
                      }
                    </div>
                  )
                })
                : ""
            }

            <div
              className="mb-4 mt-4"
              style={{
                width: "100%",
                padding: "0",
              }}
            >
              <h6
                style={{
                  fontSize: "24px",
                }}
                className="font-bold"
              >
                {STR_VISITS[appContext.language]}

              </h6>

              {
                (followupLog && followupLog['visits']) ?
                  <div
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderLeft: "10px solid var(--secondaryColor)",
                    }}
                  >
                    {
                      followupLog['visits'].map((fItem, fI) => {
                        return (
                          <div
                            style={{
                              width: "100%",

                            }}
                            key={fI}
                          >
                            <div className="text-end">
                              <span
                                className="badge bg-success"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                {formatDate(fItem.timestamp).substring(0, 10)}
                              </span>
                            </div>

                            <div className="text-start">
                              <h6
                                style={{
                                  fontSize: "14px",
                                }}
                                className="font-regular"
                              >
                                {fItem.description}
                              </h6>

                              {
                                (fItem.promiseMade === 'yes') ?
                                  <div className="mt-2">
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      className="font-regular"
                                    >
                                      <span className="text-muted font-bold">{STR_PROMISE[appContext.language]}: &nbsp;&nbsp;</span>
                                      {fItem.promiseDescription}
                                    </h6>

                                    <h6
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      className="font-regular"
                                    >
                                      <span className="text-muted font-bold">{STR_PROMISE_DUE_DATE[appContext.language]}: &nbsp;&nbsp;</span>
                                      {formatDate(fItem.promiseDueDate).substring(0, 10)}
                                    </h6>
                                  </div>
                                  : ""
                              }
                            </div>
                            <hr />
                          </div>
                        )
                      })
                    }
                  </div>
                  : ""
              }
            </div>

            <div
              className="mb-4 mt-4"
              style={{
                width: "100%",
                padding: "0",
              }}
            >
              <h6
                style={{
                  fontSize: "24px",
                }}
                className="font-bold"
              >
                {STR_PURCHASES[appContext.language]}

              </h6>

              {
                (followupLog && followupLog['sales']) ?
                  <div
                    style={{
                      width: "100%",
                      padding: "10px",
                      borderLeft: "10px solid var(--secondaryColor)",
                    }}
                  >
                    {
                      followupLog['sales'].map((fItem, fI) => {
                        return (
                          <div
                            style={{
                              width: "100%",

                            }}
                            key={fI}
                          >
                            <div className="text-end">
                              <span
                                className="badge bg-success"
                                style={{
                                  fontSize: "16px",
                                }}
                              >
                                {formatDate(fItem.timestamp).substring(0, 10)}
                              </span>
                            </div>

                            <div className="text-start">
                              <h6
                                style={{
                                  fontSize: "14px",
                                }}
                                className="font-regular"
                              >
                                {fItem.description}
                              </h6>

                              {
                                (fItem.promiseMade === 'yes') ?
                                  <div className="mt-2">
                                    <h6
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      className="font-regular"
                                    >
                                      <span className="text-muted font-bold">{STR_PROMISE[appContext.language]}: &nbsp;&nbsp;</span>
                                      {fItem.promiseDescription}
                                    </h6>

                                    <h6
                                      style={{
                                        fontSize: "14px",
                                      }}
                                      className="font-regular"
                                    >
                                      <span className="text-muted font-bold">{STR_PROMISE_DUE_DATE[appContext.language]}: &nbsp;&nbsp;</span>
                                      {formatDate(fItem.promiseDueDate).substring(0, 10)}
                                    </h6>
                                  </div>
                                  : ""
                              }
                            </div>
                            <hr />
                          </div>
                        )
                      })
                    }
                  </div>
                  : ""
              }
            </div>
          </div>
        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}