import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_ADD_STOCK, STR_AMOUNT, STR_AVAILABLE_QUANTITY, STR_BUSINESS, STR_BUSINESS_NAME, STR_CLIENT, STR_COMPANY, STR_CUSTOMER_LEVEL, STR_CUSTOMER_TYPE, STR_DASHBOARD, STR_DATE, STR_DESCRIPTION, STR_EMAIL_ADDRESS, STR_GOVERNMENT, STR_INCLUDE_COUNTRY_CODE_ON_PHONE, STR_INCLUDE_LINK_TO_PROFILE, STR_INVALID_AMOUNT, STR_INVALID_BUSINESS_NAME, STR_INVALID_DATE, STR_INVALID_NAME, STR_INVALID_PHONE, STR_INVALID_TITLE, STR_INVALID_UNIT, STR_INVALID_UNIT_PRICE, STR_LEAD, STR_LOCATION, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_NAME, STR_NO_BUSINESS_WARNING, STR_NOTES, STR_OTHER_COMM_CHANNELS, STR_PERSON, STR_PHONE_NUMBER, STR_PLEASE_WAIT, STR_RECORD_OPERATION_COST, STR_START_FREE_TRIAL, STR_SUBMIT, STR_SUCCESS, STR_TITLE, STR_TITLE_RECORD_CUSTOMER, STR_TITLE_REGISTER_BUSINESS, STR_TODOS, STR_UNIT, STR_UNIT_PRICE, STR_WATEJA } from "../Strings";
import { TiWarningOutline } from "react-icons/ti";
import { MdClose } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [dateCreated, setDateCreated] = useState("");



  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  async function record() {
    if (!loading) {
      if (title.trim().length > 0) {
        if (dateCreated.trim().length > 0) {
          if (!isNaN(totalAmount) && Number(totalAmount) > 0) {
            setLoading(true);
            await callApi(
              "record-operation-cost",
              {
                title,
                totalAmount,
                dateCreated,
                description,
                businessId: appContext.currentBusinessId,
              }
            ).then((response) => {
              if (response.status === 1) {
                appContext.tellMessage(STR_SUCCESS[appContext.language]);
                appContext.getTodayOperationCosts();
                appContext.getTotalTodayOperationCosts();
                setTitle("");
                setDescription("");
                setTotalAmount(0);
              } else {
                appContext.tellError(response.msg);
              }
            })
            setLoading(false);
          } else {
            appContext.tellError(STR_INVALID_AMOUNT[appContext.language])
          }
        } else {
          appContext.tellError(STR_INVALID_DATE[appContext.language]);
        }
      } else {
        appContext.tellError(STR_INVALID_TITLE[appContext.language]);
      }
    } else {
      appContext.tellInfo(STR_PLEASE_WAIT[appContext.language]);
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready) {
    return (

      <div className="container mw-700">
        <h6 className="mSectionTitle">{STR_RECORD_OPERATION_COST[appContext.language]}</h6>

        <div className="row">


          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_TITLE[appContext.language]}*</label>
            <input
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_DATE[appContext.language]}*</label>
            <input
              className="form-control"
              value={dateCreated}
              onChange={(e) => setDateCreated(e.target.value)}
              type="date"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="form-label">{STR_AMOUNT[appContext.language]}*</label>
            <input
              className="form-control"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              type="number"
            />
          </div>

          <div className="col-md-12 mb-4">
            <label className="form-label">{STR_DESCRIPTION[appContext.language]}</label>
            <textarea
              className="form-control"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>

          <div className="col-md-12 mb-4 text-end">
            <button
              className="btn btn-primary btn-padded btn-rounded"
              onClick={record}
            >
              {STR_SUBMIT[appContext.language]}
            </button>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}