import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL, formatDate } from "../Helpers";
import { MdCall } from "react-icons/md";
import { STR_ENTRANCE_DATE } from "../Strings";

export default function View({ data }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "bottom-view",
      subItem: "customer-options",
      extraItem: data.uid,
    });
  }

  return (
    <div className="card">
      <div className="card-body">
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.name}
        </h6>
        <h6
          className="font-semi-bold text-muted"
          style={{
            fontSize: "14px",
            textTransform: "capitalize",
          }}
        >
          <MdCall /> {data.phone}
        </h6>

        <span
          className={(data.customerLevel === 'lead') ? 'badge bg-danger' : 'badge bg-success'}
        >
          {data.customerLevel}
        </span>

        <h6
          className="text-end font-bold text-muted mt-4"
          style={{
            fontSize: "12px",
          }}
        >
          {STR_ENTRANCE_DATE[appContext.language]}: {formatDate(data.recordedDate).substring(0, 10)}
        </h6>
      </div>
    </div>
  );
}
