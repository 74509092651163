import axios from 'axios';
import Crm from './main-views/Crm';
import RegisterCustomer from './views/RegisterCustomer';
import CustomerOptions from './views/CustomerOptions';
import ReportCustomerFollowup from './views/ReportCustomerFollowup';
import ReportCustomerVisit from './views/ReportCustomerVisit';
import ReportSale from './views/ReportSale';
import Accounting from './main-views/Accounting';
import AddStock from './views/AddStock';
import RecordOperationCost from './views/RecordOperationCost';
import RecordCapital from './views/RecordCapital';
import RecordPurchase from './views/RecordPurchase';
import RecordProduction from './views/RecordProduction';
import RecordInvestment from './views/RecordInvestment';
import CreateReceivable from './views/CreateReceivable';
import CreatePayable from './views/CreatePayable';
import More from './main-views/More';
import UserOptions from './views/UserOptions';
import CreateUserGoal from './views/CreateUserGoal';
import CustomerInfo from './views/CustomerInfo';
import ViewReceivable from './views/ViewReceivable';
import ViewPayable from './views/ViewPayable';

export const BASE_API = 'https://mauzocrm.werenium.com/api/';
//export const BASE_API = 'https://mauzocrm.werenium.com/api/';

export const CDN_URL = 'https://mauzocrmapp.werenium.com/cdn/'
//export const CDN_URL = 'https://mauzocrmapp.werenium.com/cdn/'

export function getMainView(app) {
  if (app.navItem === 'crm' || !app.navItem) {
    return <Crm />;
  } else if (app.navItem === 'accounting') {
    return <Accounting />;
  } else if (app.navItem === 'more') {
    return <More />;
  }

  return null;
}


export function getViewerView(app) {
  if (
    app.navSubItem === 'register-customer' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RegisterCustomer />;
  } else if (
    app.navSubItem === 'report-customer-followup' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ReportCustomerFollowup id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'report-customer-visit' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ReportCustomerVisit id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'receivable' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewReceivable id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'payable' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <ViewPayable id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'customer-info' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CustomerInfo id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'create-user-goal' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateUserGoal id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'report-sale' &&
    (!app.navExtraItem || app.navExtraItem) &&
    !app.navMoreItem
  ) {
    return <ReportSale id={app.navExtraItem} />;
  } else if (
    app.navSubItem === 'add-stock' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <AddStock />;
  } else if (
    app.navSubItem === 'record-operation-cost' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordOperationCost />;
  } else if (
    app.navSubItem === 'record-capital' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordCapital />;
  } else if (
    app.navSubItem === 'record-purchase' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordPurchase />;
  } else if (
    app.navSubItem === 'record-production' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordProduction />;
  } else if (
    app.navSubItem === 'record-investment' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <RecordInvestment />;
  } else if (
    app.navSubItem === 'create-receivable' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreateReceivable />;
  } else if (
    app.navSubItem === 'create-payable' &&
    !app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CreatePayable />;
  }

  return null;
}

export function getSideViewerView(app) {
  if (
    app.navSubItem === '' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return "";
  }

  return null;
}

export function getBottomViewerView(app) {

  if (
    app.navSubItem === 'customer-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <CustomerOptions id={app.navExtraItem} />;
  } if (
    app.navSubItem === 'user-options' &&
    app.navExtraItem &&
    !app.navMoreItem
  ) {
    return <UserOptions id={app.navExtraItem} />;
  }

  return null;
}


export function getInlineLoader() {
  return (
    <div className="Loader"></div>
  )
}

export function remove255(input) {
  if (input.startsWith('255')) {
    return '0' + input.slice(3);
  }
  return input;
}

export function formatDate(timestamp, fromPhp = true) {
  timestamp = (fromPhp) ? Number(timestamp) * 1000 : timestamp;
  let date = new Date(timestamp);
  return (date.toLocaleString());
}

export function callApi(url, params) {
  return new Promise(async resolve => {
    await getLocalUser().then(user => {
      //..
      //call main api
      let formData = new FormData();
      for (let key in params) {
        formData.append(key, params[key]);
      }

      for (let key in user) {
        formData.append(key, user[key]);
      }

      axios.post(BASE_API + url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {

        console.log(url, response.data);

        resolve(response.data);
      }).catch(error => {
        resolve({
          status: 0,
          msg: "Network Connection Error",
        })
      })
      //..
    })

  })
}

export function getLocalUser() {
  return new Promise(async resolve => {
    //use cookies
    let userId = getCookie('userId');
    let userToken = getCookie('userToken');
    let userType = getCookie('userType');

    resolve({
      userId,
      userToken,
      userType,
    })


  })
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();

  // Get the current domain from window.location.hostname
  let domain = window.location.hostname;

  // Set domainPart only if it's not 'localhost'
  let domainPart = domain !== 'localhost' ? `;domain=${domain}` : '';

  // Add the SameSite attribute to restrict cross-origin access (Strict or Lax as per your needs)
  let sameSite = ";SameSite=Strict";

  // Add the Secure flag for HTTPS connections
  let secureFlag = window.location.protocol === 'https:' ? ";Secure" : '';

  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + domainPart + sameSite + secureFlag;
}


export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function formatMoney(x) {
  //round x to 2 decimal places before og function
  x = Number(x);
  x = x.toFixed(0);

  //then continue
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function formatMoneyShorten(num, digits = 2) {
  num = Number.parseFloat(num);
  if (num <= 1 && num >= -1) {
    return num;
  }

  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return Math.abs(num) >= item.value; // Use absolute value of num
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}
