import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { callApi, formatDate, formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ACCOUNTS_RECEIVABLE, STR_AMOUNT_PAID, STR_ASK_CONTINUE, STR_BALANCE, STR_CALL, STR_CUSTOMER_WILL_BE_CLIENT, STR_DASHBOARD, STR_DATE, STR_EDIT, STR_INITIAL_AMOUNT, STR_INVALID_AMOUNT, STR_INVALID_DATE, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_PAYMENTS, STR_PROMISE, STR_PROMISE_DUE_DATE, STR_PURCHASES, STR_RECEIVABLE, STR_RECORD_PAYMENTS, STR_REPORT_FOLLOWUP, STR_REPORT_SALES, STR_REPORT_VISIT, STR_SEND_EMAIL, STR_SEND_SMS, STR_SUBMIT, STR_SUCCESS, STR_TODOS, STR_TURN_TO_CLIENT, STR_VIEW_MORE_INFO, STR_VISITS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdCall, MdCheckCircle, MdEdit, MdEmail, MdFacebook, MdMessage, MdOutlineFacebook, MdOutlineWhatsapp, MdWhatsapp } from "react-icons/md";
import { BiInfoCircle, BiLogoLinkedin, BiLogoWhatsapp, BiPurchaseTag } from "react-icons/bi";
import { SlUserFollowing } from "react-icons/sl";
import { BsInstagram, BsTelegram } from "react-icons/bs";
import CustomerWidget from '../ones/CustomerWidget';

export default function View({ id }) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amountPaid, setAmountPaid] = useState(0);
  const [datePaid, setDatePaid] = useState("");

  async function init() {
    //run initializations here
    setReady(false);
    await getData()
    setReady(true);
  }

  async function getData() {
    await callApi("get-receivable-data", { id }).then(response => {
      if (response.status === 1) {
        setData(response.data);
      }
    })
  }

  async function submit() {
    if (datePaid.trim().length > 0) {
      if (!isNaN(amountPaid) && Number(amountPaid) > 0) {
        setLoading(true);
        await callApi("record-receivable-payment", { id, amountPaid, datePaid }).then(response => {
          if (response.status === 1) {
            appContext.tellMessage(STR_SUCCESS[appContext.language]);
            setAmountPaid(0);
            setDatePaid("");
            getData()
          } else {
            appContext.tellError(response.msg);
          }
        })
        setLoading(false);
      } else {
        appContext.tellError(STR_INVALID_AMOUNT[appContext.language]);
      }
    } else {
      appContext.tellError(STR_INVALID_DATE[appContext.language]);
    }

  }

  useEffect(() => {
    init();
  }, [id])



  useEffect(() => {
    appContext.setShowOverlayLoader(loading);
  }, [loading])

  if (ready && data) {
    return (
      <div
        className="container mw-700"
      >
        <h6
          className="mSectionTitle"
        >
          {data.title}
          <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>{STR_RECEIVABLE[appContext.language]}</span>
        </h6>
        <hr />


        <div className="row">

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_INITIAL_AMOUNT[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.totalAmount)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-6 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_BALANCE[appContext.language]}</label>
            <h6
              className="font-regular"
              style={{
                fontSize: "24px",
              }}
            >
              {formatMoney(data.balance)}
              <span className="font-semi-bold text-muted" style={{ display: "block", fontSize: "14px" }}>TZS</span>
            </h6>
          </div>

          <div className="col-12 mb-2 mt-2">
            <div className="card">
              <div className="card-body">
                <h6 className="card-title">{STR_RECORD_PAYMENTS[appContext.language]}</h6>

                <div className="mb-2 mt-4">
                  <label>{STR_AMOUNT_PAID[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={amountPaid}
                    onChange={(e) => setAmountPaid(e.target.value)}
                    type="number"
                  />
                </div>

                <div className="mb-2 mt-2">
                  <label>{STR_DATE[appContext.language]}</label>
                  <input
                    className="form-control"
                    value={datePaid}
                    onChange={(e) => setDatePaid(e.target.value)}
                    type="date"
                  />
                </div>

                <div className="mb-2 mt-2 text-end">
                  <button
                    className="btn btn-secondary btn-rounded mt-4"
                    onClick={() => {
                      appContext.activateDialog({
                        message: STR_ASK_CONTINUE[appContext.language],
                        onConfirm: submit
                      })
                    }}
                  >
                    {STR_SUBMIT[appContext.language]}
                  </button>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 mb-2 mt-2">
            <label style={{ fontSize: "12px" }} className="form-label text-secondary">{STR_PAYMENTS[appContext.language]}</label>

            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{STR_AMOUNT_PAID[appContext.language]}</th>
                  <th>{STR_DATE[appContext.language]}</th>
                </tr>
              </thead>

              <tbody>
                {
                  JSON.parse(data.history).map((item, i) => {
                    return (
                      <tr
                        key={i}
                      >
                        <td>{formatMoney(item.amountPaid)}</td>
                        <td>{formatDate(item.datePaid).substring(0, 10)}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>

        </div>

      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}