import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL, formatDate, formatMoney } from "../Helpers";
import { MdCall } from "react-icons/md";

export default function View({ data, type }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    if (type === 'user') {
      appContext.navTo({
        item: "bottom-view",
        subItem: `user-options`,
        extraItem: data.uid,
      });
    } else if (type === 'receivable') {
      appContext.navTo({
        item: "view",
        subItem: `receivable`,
        extraItem: data.id,
      });
    } else if (type === 'payable') {
      appContext.navTo({
        item: "view",
        subItem: `payable`,
        extraItem: data.id,
      });
    }
  }

  if (type === 'operation_cost') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className='badge bg-danger'
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>

        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  } else if (type === 'user') {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.name}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "18px",
          }}
        >
          {data.phone}
        </h6>

      </div>
    );
  } else {
    return (
      <div
        className="One"
        onClick={view}
      >
        <h6
          className="font-semi-bold"
          style={{
            fontSize: "18px",
            textTransform: "capitalize",
          }}
        >
          {data.title}
        </h6>


        <h6
          className=''
          style={{
            fontSize: "18px",
          }}
        >
          {formatMoney(data.totalAmount)} TZS
        </h6>

        <h6
          className='text-end'
          style={{
            fontSize: "12px",
          }}
        >
          {formatDate(data.dateCreated).substring(0, 10)}
        </h6>
      </div>
    );
  }
}
