import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_AVAILABLE_STOCK, STR_CLIENTS, STR_DASHBOARD, STR_FILTER_BY_NAME, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_NO_RECORDS, STR_OPERATION_COSTS_TODAY, STR_SALES_TODAY, STR_STOCKLIST, STR_TODOS, STR_WATEJA } from "../Strings";
import { MdSearch } from "react-icons/md";
import OneRecord from '../ones/OneRecord';

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");


  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getTodayOperationCosts();
    await appContext.getTotalTodayOperationCosts();
    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])


  if (ready) {
    return (
      <div
        className="container"

      >
        <div className="row">
          <div className="col-12 col-md-12 mb-3 mt-3">
            <div className="card" style={{ height: "100%" }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6
                    className="mSectionTitle mNoMargin"
                  >
                    {STR_OPERATION_COSTS_TODAY[appContext.language]}
                  </h6>

                  <button
                    className="btn btn-secondary btn-sm btn-rounded"
                    style={{
                      flexShrink: "0",
                      height: "30px",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: "view",
                        subItem: "record-operation-cost",
                      })
                    }}
                  >
                    {STR_ADD_NEW[appContext.language]}
                  </button>
                </div>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "40px",
                  }}
                  className="text-secondary font-bold"
                >
                  {formatMoney(appContext.totalTodayOperationCosts)}
                  <span
                    style={{
                      fontSize: "18px",
                      display: "block",
                    }}
                    className="font-light"
                  >
                    TZS
                  </span>
                </h1>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-12 mb-3 mt-3">

            <div className="row mb-4">
              <div
                className="col-md-3"
              >
                <div
                  className="input-group"
                >
                  <span
                    className="input-group-text font-semi-bold text-secondary"
                    style={{
                      fontSize: "22px"
                    }}
                  >
                    <MdSearch size={24} />
                  </span>
                  <input
                    className="form-control"
                    onChange={(e) => setFilter(e.target.value)}
                    value={filter}
                    placeholder={STR_FILTER_BY_NAME[appContext.language]}
                  />
                </div>
              </div>


            </div>

            {
              (appContext.todayOperationCosts && appContext.todayOperationCosts.length > 0) ?
                appContext.todayOperationCosts.map((item, i) => {
                  if (filter && filter.trim().length > 0) {
                    if (
                      String(item.title).toUpperCase().indexOf(String(filter).toUpperCase()) > -1
                    ) {
                      return (
                        <OneRecord type="operation_cost" data={item} key={i} />
                      )
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <OneRecord type="operation_cost" data={item} key={i} />
                    )
                  }

                })
                : <h6 style={{ fontSize: "14px", }}>{STR_NO_RECORDS[appContext.language]}</h6>
            }

          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}