import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_BONUS_EARNED, STR_CLIENTS, STR_COMMISSION_EARDNED, STR_COMPLETED_TODOS, STR_CREATE_TODO, STR_DASHBOARD, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_MY_SALES, STR_NO_RECORDS, STR_PENDING_TODOS, STR_TODAY_TODOS, STR_TODOS, STR_UNIQUE_CUSTOMERS_ENGAGED, STR_VIEW, STR_VIEW_CURRENT_GOALS, STR_WATEJA } from "../Strings";
import { MdPlaylistAdd, MdSearch } from "react-icons/md";
import OneCustomer from '../ones/OneCustomer';
import { TbTarget } from "react-icons/tb";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");
  const [category, setCategory] = useState("all");
  const [customersList, setCustomersList] = useState([])
  const [clientsList, setClientsList] = useState([])
  const [leadsList, setLeadsList] = useState([])

  async function init() {
    //run initializations here
    setReady(false);
    await appContext.getMyCustomers();
    setReady(true);
  }

  function groupCustomers() {
    if (appContext.myCustomers) {
      const _clients = [];
      const _leads = [];

      for (let _customer of appContext.myCustomers) {
        if (_customer.customerLevel === 'lead') {
          _leads.push(_customer)
        } else if (_customer.customerLevel === 'client') {
          _clients.push(_customer)
        }
      }

      setClientsList(_clients);
      setLeadsList(_leads);

      if (category === 'all') {
        setCustomersList(appContext.myCustomers);
      } else if (category === 'leads') {
        setCustomersList(_leads);
      } else if (category === 'clients') {
        setCustomersList(_clients);
      }
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    groupCustomers();
  }, [appContext.myCustomers, category])

  if (ready) {
    return (
      <div
        className="container"

      >
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mt-3">
            <div className="card" style={{ height: "100%" }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6
                    className="mSectionTitle mNoMargin"
                  >
                    {STR_TODAY_TODOS[appContext.language]}
                  </h6>

                </div>

                <h1
                  style={{
                    fontSize: "48px",
                    marginTop: "40px",
                  }}
                  className="text-secondary font-bold"
                >
                  {0}
                </h1>

                <div className="text-end">
                  <button
                    className="btn btn-sm btn-rounded btn-secondary"
                  >
                    {STR_VIEW[appContext.language]}
                  </button>
                </div>
              </div>
            </div>
          </div>



          <div className="col-6 col-md-3 mb-3 mt-3">
            <div
              className='card'
              style={{
                height: "100%",
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_COMPLETED_TODOS[appContext.language]}
                </h6>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "20px",
                  }}
                >
                  {0}
                </h1>

                <div className="text-end">
                  <button
                    className="btn btn-sm btn-rounded btn-secondary"
                  >
                    {STR_VIEW[appContext.language]}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 mb-3 mt-3">
            <div
              className='card'
              style={{
                height: "100%",
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_PENDING_TODOS[appContext.language]}
                </h6>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "20px",
                  }}
                >
                  {0}
                </h1>

                <div className="text-end">
                  <button
                    className="btn btn-sm btn-rounded btn-secondary"
                  >
                    {STR_VIEW[appContext.language]}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 mt-3">
            <button
              className="btn btn-block btn-padded btn-secondary"
              style={{
                height: "100%",
                fontSize: "20px"
              }}
            >
              <MdPlaylistAdd size={80} /><br />
              {STR_CREATE_TODO[appContext.language]}
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}