import { useContext } from "react"
import { AppContext } from "../App"
import { MdHome, MdLogout, MdMenu, MdMore, MdMoreHoriz, MdNotifications, MdOutlineMessage, MdOutlineNotifications } from "react-icons/md";
import { IoNotificationsOutline, IoRefreshCircleOutline } from "react-icons/io5";
import { TbMessageDots } from "react-icons/tb";
import { callApi, setCookie } from "../Helpers";
import logo_worded_only from '../assets/logo_worded_only.png';
import { AiOutlineUser } from 'react-icons/ai';
import { STR_ACCOUNTING, STR_CRM, STR_LEADS, STR_MENU_DATING, STR_MENU_DOCTORS, STR_MENU_HOME, STR_MENU_INBOX, STR_MORE, STR_REPORTS } from "../Strings";
import { RiHome6Fill, RiHomeLine } from 'react-icons/ri';
import { GiLovers, GiTalk } from "react-icons/gi";
import { FaUserDoctor } from "react-icons/fa6";
import { TbFileAnalytics } from "react-icons/tb";



export default function BottomBar(props) {

  const appContext = useContext(AppContext);

  return (
    <div
      className="BottomBar"
    >
      <div className="body d-flex justify-content-between container">

        <div
          className="mIcon text-center"
          data-active={appContext.navItem === 'crm' || !appContext.navItem}
          onClick={() => {
            appContext.navTo({
              item: 'crm',
            })
          }}
        >
          <RiHome6Fill className="mSymbol" />
          <h6 className="mText">{STR_CRM[appContext.language]}</h6>
        </div>

        <div
          className="mIcon text-center"
          data-active={appContext.navItem === 'accounting'}
          onClick={() => {
            appContext.navTo({
              item: 'accounting',
            })
          }}
        >
          <TbFileAnalytics className="mSymbol" />
          <h6 className="mText">{STR_ACCOUNTING[appContext.language]}</h6>
        </div>

        <div
          className="mIcon text-center"
          data-active={appContext.navItem === 'more'}
          onClick={() => {
            appContext.navTo({
              item: 'more',
            })
          }}
        >
          <MdMoreHoriz className="mSymbol" />
          <h6 className="mText">{STR_MORE[appContext.language]}</h6>
        </div>

      </div>
    </div>
  )
}