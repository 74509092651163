import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_BUSINESS, STR_DASHBOARD, STR_LEADS_DISCOVERY, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_REPORTS, STR_TODOS, STR_USERS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import { MdBusiness, MdPeople } from "react-icons/md";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  if (ready) {
    return (
      <div
        className="container"
      >
        <div className="row">

          <div className="col-md-6">
            <div
              className="card"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'more',
                  subItem: 'users',
                })
              }}
            >
              <div className="card-body">
                <div className="d-flex">
                  <MdPeople
                    className="mNoMargin align-self-center"
                    size={32}
                    style={{
                      flexShrink: "0",
                    }}
                  />

                  <h6
                    className="mNoMargin align-self-center"
                    style={{
                      flexGrow: "1",
                      padding: "8px",
                    }}
                  >
                    {STR_USERS[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="card"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'more',
                  subItem: 'business',
                })
              }}
            >
              <div className="card-body">
                <div className="d-flex">
                  <MdBusiness
                    className="mNoMargin align-self-center"
                    size={32}
                    style={{
                      flexShrink: "0",
                    }}
                  />

                  <h6
                    className="mNoMargin align-self-center"
                    style={{
                      flexGrow: "1",
                      padding: "8px",
                    }}
                  >
                    {STR_BUSINESS[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="card"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'more',
                  subItem: 'leads_discovery',
                })
              }}
            >
              <div className="card-body">
                <div className="d-flex">
                  <MdBusiness
                    className="mNoMargin align-self-center"
                    size={32}
                    style={{
                      flexShrink: "0",
                    }}
                  />

                  <h6
                    className="mNoMargin align-self-center"
                    style={{
                      flexGrow: "1",
                      padding: "8px",
                    }}
                  >
                    {STR_LEADS_DISCOVERY[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="card"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                appContext.navTo({
                  item: 'more',
                  subItem: 'leads_discovery',
                })
              }}
            >
              <div className="card-body">
                <div className="d-flex">
                  <MdBusiness
                    className="mNoMargin align-self-center"
                    size={32}
                    style={{
                      flexShrink: "0",
                    }}
                  />

                  <h6
                    className="mNoMargin align-self-center"
                    style={{
                      flexGrow: "1",
                      padding: "8px",
                    }}
                  >
                    {STR_REPORTS[appContext.language]}
                  </h6>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}