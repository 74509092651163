import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL } from "../Helpers";
import { MdCall } from "react-icons/md";

export default function View({ data }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    appContext.navTo({
      item: "bottom-view",
      subItem: "customer-options",
      extraItem: data.uid,
    });
  }

  return (
    <div
      className="One"
      onClick={view}
    >
      <h6
        className="font-semi-bold"
        style={{
          fontSize: "18px",
          textTransform: "capitalize",
        }}
      >
        {data.name}
      </h6>
      <h6
        className="font-semi-bold text-muted"
        style={{
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        <MdCall /> {data.phone}
      </h6>

      <span
        className={(data.customerLevel === 'lead') ? 'badge bg-danger' : 'badge bg-success'}
      >
        {data.customerLevel}
      </span>
    </div>
  );
}
