import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import AccountingLanding from "../views/AccountingLanding";
import RegisterBusiness from "../views/RegisterBusiness";
import Capital from '../views/Capital';
import Purchases from '../views/Purchases';
import Production from '../views/Production';
import Investment from '../views/Investment';
import Stock from '../views/Stock';
import Sales from '../views/Sales';
import OperationCosts from '../views/OperationCosts';
import Receivables from '../views/Receivables';
import Payables from '../views/Payables';



export default function Accounting(props) {

  const appContext = useContext(AppContext);

  const [view, setView] = useState(null);

  async function init() {
    //run initializations here
  }

  function getView() {
    if (appContext.doesUserHasBusiness()) {
      if (
        !appContext.navSubItem &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <AccountingLanding />
      } else if (
        appContext.navSubItem === 'capital' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Capital />
      } else if (
        appContext.navSubItem === 'purchases' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Purchases />
      } else if (
        appContext.navSubItem === 'production' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Production />
      } else if (
        appContext.navSubItem === 'investment' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Investment />
      } else if (
        appContext.navSubItem === 'stock' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Stock />
      } else if (
        appContext.navSubItem === 'sales' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Sales />
      } else if (
        appContext.navSubItem === 'operation_costs' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <OperationCosts />
      } else if (
        appContext.navSubItem === 'receivables' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Receivables />
      } else if (
        appContext.navSubItem === 'payables' &&
        !appContext.navExtraItem &&
        !appContext.navMoreItem
      ) {
        return <Payables />
      }
    } else {
      return <RegisterBusiness />
    }

    return null;
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    const _view = getView();
    if (_view) {
      setView(_view);
    }
  }, [appContext.navSubItem, appContext.navExtraItem, appContext.navMoreItem])

  //return desired view
  return view;
}