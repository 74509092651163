import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_DASHBOARD, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_TODOS, STR_WATEJA } from "../Strings";
import CustomersHome from '../views/CustomersHome';
import CrmDashboard from '../views/CrmDashboard';
import Todos from '../views/Todos';


export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);

  async function init() {
    //run initializations here
    setReady(false);
    //..

    setReady(true);
  }

  useEffect(() => {
    init();
  }, [])

  if (ready) {
    return (
      <div
        className="MainLanding"
      >
        <TabbedView
          tabs={
            [
              { id: 0, label: STR_DASHBOARD[appContext.language], content: <CrmDashboard /> },
              { id: 1, label: STR_WATEJA[appContext.language], content: <CustomersHome /> },
              { id: 2, label: STR_TODOS[appContext.language], content: <Todos /> },
            ]
          }
        />
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}