import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { CDN_URL, formatMoney } from "../Helpers";
import { MdCall } from "react-icons/md";

export default function View({ data }) {
  const appContext = useContext(AppContext);

  /* Place states here */


  function view() {
    /*
    appContext.navTo({
      item: "bottom-view",
      subItem: "stock-options",
      extraItem: data.uid,
    });
    */
  }

  return (
    <div
      className="One"
      onClick={view}
    >
      <h6
        className="font-semi-bold"
        style={{
          fontSize: "18px",
          textTransform: "capitalize",
        }}
      >
        {data.name}
      </h6>
      <h6
        className="font-semi-bold text-muted"
        style={{
          fontSize: "14px",
          textTransform: "capitalize",
        }}
      >
        {data.quantity} {data.unit}
      </h6>

      <h6
        className='badge bg-success'
        style={{
          fontSize: "18px",
        }}
      >
        {formatMoney(data.unitPrice)} TZS
      </h6>
    </div>
  );
}
