import { useContext, useEffect, useState } from "react"
import { AppContext } from "../App"
import { PiBuildingOfficeBold, PiStudent } from "react-icons/pi";
import { formatMoney, formatMoneyShorten } from "../Helpers";
import { FaChalkboardTeacher } from "react-icons/fa";
import { SiGoogleclassroom } from "react-icons/si";
import { IoIosPeople } from "react-icons/io";
import { getInlineLoader } from "../Helpers";
import TabbedView from "../components/TabbedView";
import { STR_ADD_NEW, STR_BONUS_EARNED, STR_CLIENTS, STR_COMMISSION_EARDNED, STR_DASHBOARD, STR_FILTER_BY_NAME_OR_PHONE, STR_LEADS, STR_MENU_ARTICLES, STR_MENU_STORIES, STR_MENU_UTILITIES, STR_MY_CUSTOMERS, STR_MY_RECORDED_CUSTOMERS, STR_MY_SALES, STR_NO_RECORDS, STR_THIS_MONTH, STR_THIS_WEEK, STR_TODAY, STR_TODOS, STR_UNIQUE_CUSTOMERS_ENGAGED, STR_VIEW_CURRENT_GOALS, STR_WATEJA } from "../Strings";
import { MdSearch } from "react-icons/md";
import OneCustomer from '../ones/OneCustomer';
import { TbTarget } from "react-icons/tb";

export default function View(props) {

  const appContext = useContext(AppContext);

  /* Place states here */
  const [ready, setReady] = useState(false);
  const [filter, setFilter] = useState("");
  const [category, setCategory] = useState("all");
  const [customersList, setCustomersList] = useState([])
  const [clientsList, setClientsList] = useState([])
  const [leadsList, setLeadsList] = useState([])

  async function init() {
    //run initializations here
    setReady(false);

    await Promise.all([
      appContext.getMyTotalSalesThisMonth(),
      appContext.getMyTotalSalesThisWeek(),
      appContext.getMyTotalSalesToday(),
      appContext.getMyCustomersRecordedToday(),
      appContext.getMyCustomersRecordedThisWeek(),
      appContext.getMyCustomersRecordedThisMonth()
    ]);

    setReady(true);
  }

  function groupCustomers() {
    if (appContext.myCustomers) {
      const _clients = [];
      const _leads = [];

      for (let _customer of appContext.myCustomers) {
        if (_customer.customerLevel === 'lead') {
          _leads.push(_customer)
        } else if (_customer.customerLevel === 'client') {
          _clients.push(_customer)
        }
      }

      setClientsList(_clients);
      setLeadsList(_leads);

      if (category === 'all') {
        setCustomersList(appContext.myCustomers);
      } else if (category === 'leads') {
        setCustomersList(_leads);
      } else if (category === 'clients') {
        setCustomersList(_clients);
      }
    }
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    groupCustomers();
  }, [appContext.myCustomers, category])

  if (ready) {
    return (
      <div
        className="container"

      >
        <div className="row">
          <div className="col-12 col-md-6 mb-3 mt-3">
            <div className="card" style={{ height: "100%" }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6
                    className="mSectionTitle mNoMargin"
                  >
                    {STR_MY_SALES[appContext.language]}
                  </h6>

                  <button
                    className="btn btn-secondary btn-sm btn-rounded"
                    style={{
                      flexShrink: "0",
                      height: "30px",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: "view",
                        subItem: "report-sale",
                      })
                    }}
                  >
                    {STR_ADD_NEW[appContext.language]}
                  </button>
                </div>

                <div className="row" style={{ marginTop: "40px" }}>
                  <div
                    className="col-4"
                  >
                    <h6
                      style={{
                        fontSize: "26px",
                      }}
                      className="font-light"
                    >
                      {formatMoneyShorten(appContext.myTotalSalesToday)}
                      <span className="text-muted font-bold" style={{ display: "block", fontSize: "12px" }}>TZS</span>
                    </h6>

                    <h6
                      className="font-bold text-secondary"
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      {STR_TODAY[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="col-4"
                    style={{
                      borderLeft: "1px solid var(--darkColor)",
                      borderRight: "1px solid var(--darkColor)",
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "26px",
                      }}
                      className="font-light"
                    >
                      {formatMoneyShorten(appContext.myTotalSalesThisWeek)}
                      <span className="text-muted font-bold" style={{ display: "block", fontSize: "12px" }}>TZS</span>
                    </h6>

                    <h6
                      className="font-bold text-secondary"
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      {STR_THIS_WEEK[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="col-4"
                  >
                    <h6
                      style={{
                        fontSize: "26px",
                      }}
                      className="font-light"
                    >
                      {formatMoneyShorten(appContext.myTotalSalesThisMonth)}
                      <span className="text-muted font-bold" style={{ display: "block", fontSize: "12px" }}>TZS</span>
                    </h6>

                    <h6
                      className="font-bold text-secondary"
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      {STR_THIS_MONTH[appContext.language]}
                    </h6>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 mt-3">
            <div className="card" style={{ height: "100%" }}>
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h6
                    className="mSectionTitle mNoMargin"
                  >
                    {STR_MY_RECORDED_CUSTOMERS[appContext.language]}
                  </h6>

                  <button
                    className="btn btn-secondary btn-sm btn-rounded"
                    style={{
                      flexShrink: "0",
                      height: "30px",
                    }}
                    onClick={() => {
                      appContext.navTo({
                        item: "view",
                        subItem: "register-customer",
                      })
                    }}
                  >
                    {STR_ADD_NEW[appContext.language]}
                  </button>
                </div>

                <div className="row" style={{ marginTop: "40px" }}>
                  <div
                    className="col-4"
                  >
                    <h6
                      style={{
                        fontSize: "26px",
                      }}
                      className="font-light"
                    >
                      {formatMoneyShorten(appContext.myCustomersRecordedToday)}
                    </h6>

                    <h6
                      className="font-bold text-secondary"
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      {STR_TODAY[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="col-4"
                    style={{
                      borderLeft: "1px solid var(--darkColor)",
                      borderRight: "1px solid var(--darkColor)",
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "26px",
                      }}
                      className="font-light"
                    >
                      {formatMoneyShorten(appContext.myCustomersRecordedThisWeek)}
                    </h6>

                    <h6
                      className="font-bold text-secondary"
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      {STR_THIS_WEEK[appContext.language]}
                    </h6>
                  </div>

                  <div
                    className="col-4"
                  >
                    <h6
                      style={{
                        fontSize: "26px",
                      }}
                      className="font-light"
                    >
                      {formatMoneyShorten(appContext.myCustomersRecordedThisMonth)}
                    </h6>

                    <h6
                      className="font-bold text-secondary"
                      style={{
                        fontSize: "14px",
                        marginTop: "20px",
                      }}
                    >
                      {STR_THIS_MONTH[appContext.language]}
                    </h6>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 mb-3 mt-3">
            <div
              className='card'
              style={{
                height: "100%",
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_BONUS_EARNED[appContext.language]}
                </h6>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "20px",
                  }}
                >
                  {0}
                  <span style={{ display: "block", fontSize: "14px" }}>TZS</span>
                </h1>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 mb-3 mt-3">
            <div
              className='card'
              style={{
                height: "100%",
                cursor: "pointer",
              }}
            >
              <div className="card-body">
                <h6
                  className="font-bold"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  {STR_COMMISSION_EARDNED[appContext.language]}
                </h6>

                <h1
                  style={{
                    fontSize: "30px",
                    marginTop: "20px",
                  }}
                >
                  {0}
                  <span style={{ display: "block", fontSize: "14px" }}>TZS</span>
                </h1>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 mt-3">
            <button
              className="btn btn-block btn-padded btn-secondary"
              style={{
                height: "100%",
                fontSize: "20px"
              }}
            >
              <TbTarget size={80} /><br />
              {STR_VIEW_CURRENT_GOALS[appContext.language]}
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div
        className="container mSupportLoading"
      >
        {getInlineLoader()}
      </div>
    );
  }


}