import { useState, useEffect, createContext } from "react";
import { getMainView, getViewerView, getSideViewerView, callApi, getBottomViewerView, setCookie } from "./Helpers";
import { useParams } from "react-router-dom";
import Viewer from "./components/Viewer";
import MainBody from "./components/MainBody";
import Backdrop from "./components/Backdrop";
import SideViewer from "./components/SideViewer";
import ConfirmDialog from "./components/ConfirmDialog";
import { ToastContainer, toast } from 'react-toastify';
import SideBar from "./components/SideBar";
import TopBar from "./components/TopBar";
import MainMenuBtn from "./components/MainMenuBtn";
import Login from "./views/Login";
import OverlayLoader from "./components/OverlayLoader";
import MainLoader from "./components/MainLoader";
import BottomBar from "./components/BottomBar";
import BottomViewer from "./components/BottomViewer";

export const AppContext = createContext(null);

export default function App(props) {

  const [ready, setReady] = useState(false); //for checking if app is ready

  const { _navItem, _navSubItem, _navExtraItem, _navMoreItem } = useParams(); //for routing purposes

  const [navItem, setNavItem] = useState(_navItem); //routing 
  const [navSubItem, setNavSubItem] = useState(_navSubItem); //routing
  const [navExtraItem, setNavExtraItem] = useState(_navExtraItem); //routing
  const [navMoreItem, setNavMoreItem] = useState(_navMoreItem); //routing

  const [showViewer, setShowViewer] = useState(false); //controlling the display of Viewer component
  const [viewerView, setViewerView] = useState(null); //the view to be shown in viewer

  const [showSideViewer, setShowSideViewer] = useState(false); //controlling the display of SideViewer component
  const [sideViewerView, setSideViewerView] = useState(null); //the view to be shown in viewer

  const [showBottomViewer, setShowBottomViewer] = useState(false); //controlling the display of BottomViewer component
  const [bottomViewerView, setBottomViewerView] = useState(null); //the view to be shown in viewer

  const [mainView, setMainView] = useState(null); //the view tobe shown in MainBody

  const [showOverlayLoader, setShowOverlayLoader] = useState(false); //controlling the display of OverlayLoader

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = useState("");
  const [confirmDialogAction, setConfirmDialogAction] = useState("");

  const [isSideBarOpen, setIsSideBarOpen] = useState(false); //controls open and close for sidebar in mobile devices

  const [auth, setAuth] = useState(false); //track user authorization status

  //currentSession
  const [currentUserData, setCurrentUserData] = useState(null);
  const [currentBusinessId, setCurrentBusinessId] = useState(null);
  const [currentBusinessData, setCurrentBusinessData] = useState(null);
  const [theme, setTheme] = useState('default');
  const [language, setLanguage] = useState("en");

  //App level data
  const [systemParams, setSystemParams] = useState(null);
  const [myCustomers, setMyCustomers] = useState(null);
  const [stockList, setStockList] = useState(null);
  const [totalStockValue, setTotalStockValue] = useState(0);
  const [totalTodaySales, setTotalTodaySales] = useState(0);
  const [todayOperationCosts, setTodayOperationCosts] = useState(null);
  const [totalTodayOperationCosts, setTotalTodayOperationCosts] = useState(0);
  const [totalCapital, setTotalCapital] = useState(0);
  const [capitalRecords, setCapitalRecords] = useState(null);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [purchasesRecords, setPurchasesRecords] = useState(null);
  const [totalProduction, setTotalProduction] = useState(0);
  const [productionRecords, setProductionRecords] = useState(null);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [investmentRecords, setInvestmentRecords] = useState(null);
  const [totalSales, setTotalSales] = useState(0);
  const [salesRecords, setSalesRecords] = useState(null);
  const [totalOperationCosts, setTotalOperationCosts] = useState(0);
  const [operationCostsRecords, setOperationCostsRecords] = useState(null);
  const [totalReceivables, setTotalReceivables] = useState(0);
  const [receivables, setReceivables] = useState(null);
  const [totalPayables, setTotalPayables] = useState(0);
  const [payables, setPayables] = useState(null);
  const [businessUsers, setBusinessUsers] = useState(null);
  const [myTotalSalesToday, setMyTotalSalesToday] = useState(0);
  const [myTotalSalesThisWeek, setMyTotalSalesThisWeek] = useState(0);
  const [myTotalSalesThisMonth, setMyTotalSalesThisMonth] = useState(0);
  const [myCustomersRecordedToday, setMyCustomersRecordedToday] = useState(0);
  const [myCustomersRecordedThisWeek, setMyCustomersRecordedThisWeek] = useState(0);
  const [myCustomersRecordedThisMonth, setMyCustomersRecordedThisMonth] = useState(0);

  async function init() {
    /**
     * Initialize the app here
     */

    setReady(false);
    await getSystemParams().then(async () => {
      await authCheck().then(async (_auth) => {
        if (_auth) {
          await getCurrentUserData().then(_userData => {
            //set defaul business
            if (_userData) {
              if (_userData.businesses) {
                const _businesses = JSON.parse(_userData.businesses);

                if (_businesses.length > 0) {
                  setCurrentBusinessId(_businesses[0].id)
                }
              }
            }
          });

        }
      })


      //turn off loaders after initialization
      setReady(true);
    })

  }

  function navBack() {
    window.history.back();
    setShowOverlayLoader(false);
  }

  function tellError(msg) {
    toast.error(msg);
  }

  function tellInfo(msg) {
    toast.info(msg);
  }

  function tellWarning(msg) {
    toast.warn(msg);
  }

  function tellMessage(msg) {
    toast.success(msg);
  }

  function refresh() {
    /**
     * This function refreshes the whole app
     */
    console.log("REFRESH CALLED");

    window.location.reload(); //remember to optimize
  }

  function navTo(nav) {
    /**
     * This function handles navigation inside the app
     * Utilizing Hash based routing
     * nav is the object supporting the following keys: item, subItem, extraItem, moreItem
     */
    if (nav) {
      //..

      let url = '';
      if (nav.item) {
        url = `#/${nav.item}/`
      }

      if (nav.subItem) {
        url += `${nav.subItem}/`
      }

      if (nav.extraItem) {
        url += `${nav.extraItem}/`
      }

      if (nav.moreItem) {
        url += `${nav.moreItem}/`
      }

      window.location.href = url;
      //..
    }
  }

  async function getCurrentUserData() {
    return new Promise(async resolve => {
      await callApi('get-current-user-data', {}).then(response => {
        if (response.status === 1) {
          setCurrentUserData(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getSystemParams() {
    return new Promise(async resolve => {
      await callApi('get-system-params', {}).then(response => {
        if (response.status === 1) {
          setSystemParams(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomers() {
    return new Promise(async resolve => {
      await callApi('get-my-customers', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomers(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getStockList() {
    return new Promise(async resolve => {
      await callApi('get-stock-list', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setStockList(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalStockValue() {
    return new Promise(async resolve => {
      await callApi('get-total-stock-value', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalStockValue(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalCapital() {
    return new Promise(async resolve => {
      await callApi('get-total-capital', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalCapital(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalOperationCosts() {
    return new Promise(async resolve => {
      await callApi('get-total-operation-costs', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalOperationCosts(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getOperationCostsRecords() {
    return new Promise(async resolve => {
      await callApi('get-operation-costs-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setOperationCostsRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalInvestment() {
    return new Promise(async resolve => {
      await callApi('get-total-investment', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalInvestment(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalReceivables() {
    return new Promise(async resolve => {
      await callApi('get-total-receivables', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalReceivables(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getReceivables() {
    return new Promise(async resolve => {
      await callApi('get-receivables', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setReceivables(response.data);
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async function getBusinessUsers() {
    return new Promise(async resolve => {
      await callApi('get-business-users', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setBusinessUsers(response.data);
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async function getPayables() {
    return new Promise(async resolve => {
      await callApi('get-payables', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setPayables(response.data);
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async function getTotalPayables() {
    return new Promise(async resolve => {
      await callApi('get-total-payables', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalPayables(response.data);
          resolve(response.data);
        } else {
          resolve(null);
        }
      })
    })
  }

  async function getInvestmentRecords() {
    return new Promise(async resolve => {
      await callApi('get-investment-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setInvestmentRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalTodayOperationCosts() {
    return new Promise(async resolve => {
      await callApi('get-total-today-operation-costs', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalTodayOperationCosts(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTodayOperationCosts() {
    return new Promise(async resolve => {
      await callApi('get-today-operation-costs', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTodayOperationCosts(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getCapitalRecords() {
    return new Promise(async resolve => {
      await callApi('get-capital-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setCapitalRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getProductionRecords() {
    return new Promise(async resolve => {
      await callApi('get-production-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setProductionRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getSalesRecords() {
    return new Promise(async resolve => {
      await callApi('get-sales-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setSalesRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalProduction() {
    return new Promise(async resolve => {
      await callApi('get-total-production', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalProduction(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getPurchasesRecords() {
    return new Promise(async resolve => {
      await callApi('get-purchases-records', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setPurchasesRecords(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalPurchases() {
    return new Promise(async resolve => {
      await callApi('get-total-purchases', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalPurchases(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalTodaySales() {
    return new Promise(async resolve => {
      await callApi('get-total-today-sales', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalTodaySales(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getTotalSales() {
    return new Promise(async resolve => {
      await callApi('get-total-sales', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setTotalSales(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getCurrentBusinessData() {
    return new Promise(async resolve => {
      if (currentBusinessId) {
        await callApi('get-business-data', { id: currentBusinessId }).then(response => {
          if (response.status === 1) {
            setCurrentBusinessData(response.data);
            resolve(response.data)
          } else {
            resolve(null)
          }
        })
      } else {
        resolve(null)
      }
    })
  }

  function doesUserHasBusiness() {
    if (currentUserData) {
      if (currentUserData) {
        if (JSON.parse(currentUserData.businesses).length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  function authCheck() {
    return new Promise(async resolve => {
      await callApi('auth-check', {}).then(response => {
        if (response.status === 1) {
          setAuth(true);
          resolve(true)
        } else {
          setAuth(false);
          resolve(false);
        }
      })
    })
  }

  function changeLanguage() {
    let _lang = (language === 'en') ? 'sw' : 'en';
    setLanguage(_lang);
    setCookie('language', _lang);
  }

  function activateDialog(params) {
    let {
      message,
      onConfirm
    } = params;
    setConfirmDialogAction(() => { return onConfirm });
    setConfirmDialogMessage(message)
    setShowConfirmDialog(true);
  }

  function getUserMetricDescription(_id) {
    const _userMetrics = JSON.parse(systemParams.userMetrics);
    let _desc = "";

    for (const _metric of _userMetrics) {
      if (_metric.id === _id) {
        _desc = _metric.description[language];
        break;
      }
    }

    return _desc;
  }

  async function getMyTotalSalesToday() {
    return new Promise(async resolve => {
      await callApi('get-my-total-sales-today', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTotalSalesToday(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyTotalSalesThisWeek() {
    return new Promise(async resolve => {
      await callApi('get-my-total-sales-this-week', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTotalSalesThisWeek(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyTotalSalesThisMonth() {
    return new Promise(async resolve => {
      await callApi('get-my-total-sales-this-month', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyTotalSalesThisMonth(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomersRecordedToday() {
    return new Promise(async resolve => {
      await callApi('get-my-customers-recorded-today', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomersRecordedToday(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomersRecordedThisWeek() {
    return new Promise(async resolve => {
      await callApi('get-my-customers-recorded-this-week', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomersRecordedThisWeek(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  async function getMyCustomersRecordedThisMonth() {
    return new Promise(async resolve => {
      await callApi('get-my-customers-recorded-this-month', { businessId: currentBusinessId }).then(response => {
        if (response.status === 1) {
          setMyCustomersRecordedThisMonth(response.data);
          resolve(response.data)
        } else {
          resolve(null)
        }
      })
    })
  }

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (isSideBarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isSideBarOpen])

  useEffect(() => {
    setNavItem(_navItem);
    setNavSubItem(_navSubItem);
    setNavExtraItem(_navExtraItem);
    setNavMoreItem(_navMoreItem);
  }, [_navItem, _navSubItem, _navExtraItem, _navMoreItem])

  useEffect(() => {
    if (currentUserData) {
      //handle current user data changes here
    }
  }, [currentUserData])

  useEffect(() => {
    if (currentBusinessId) {
      //handle current business id change
      getCurrentBusinessData();
      setMyCustomers(null);
    }
  }, [currentBusinessId])


  useEffect(() => {

    //check for viewers
    if (navItem === 'view') {
      //activate viewer
      setShowViewer(true);
      setViewerView(getViewerView(appContext));

      //hide other viewers
      setShowSideViewer(false)
      setSideViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

    } else if (navItem === 'side-view') {
      //activate viewer
      setShowSideViewer(true);
      setSideViewerView(getSideViewerView(appContext));

      //hide other viewers
      setShowViewer(false)
      setViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

    } else if (navItem === 'bottom-view') {
      //activate viewer
      setShowBottomViewer(true);
      setBottomViewerView(getBottomViewerView(appContext));

      //hide other viewers
      setShowViewer(false)
      setViewerView(null);

      setShowSideViewer(false)
      setSideViewerView(null);

    } else {
      //just set normal views
      setShowViewer(false);
      setShowSideViewer(false)

      setViewerView(null);
      setSideViewerView(null);

      setShowBottomViewer(false);
      setBottomViewerView(null);

      const _mainView = getMainView(appContext)
      if (_mainView) {
        setMainView(_mainView);
      }
    }
  }, [navItem, navSubItem, navExtraItem, navMoreItem]);

  const appContext = {
    refresh,
    navTo,
    mainView,
    viewerView,
    setShowOverlayLoader,
    navItem,
    navSubItem,
    navExtraItem,
    navMoreItem,
    setShowViewer,
    showViewer,
    navBack,
    showSideViewer,
    setShowSideViewer,
    sideViewerView,
    activateDialog,
    setShowConfirmDialog,
    confirmDialogAction,
    confirmDialogMessage,
    showConfirmDialog,
    tellError,
    tellInfo,
    tellMessage,
    tellWarning,
    isSideBarOpen,
    setIsSideBarOpen,
    showOverlayLoader,
    auth,
    currentUserData,
    theme,
    language,
    doesUserHasBusiness,
    currentBusinessData,
    currentBusinessId,
    getCurrentBusinessData,
    getCurrentUserData,
    bottomViewerView,
    showBottomViewer,
    changeLanguage,
    systemParams,
    getSystemParams,
    myCustomers,
    getMyCustomers,
    getStockList,
    stockList,
    totalStockValue,
    getTotalStockValue,
    getTotalTodaySales,
    totalTodaySales,
    totalTodayOperationCosts,
    getTotalTodayOperationCosts,
    todayOperationCosts,
    getTodayOperationCosts,
    totalCapital,
    getTotalCapital,
    getCapitalRecords,
    capitalRecords,
    getPurchasesRecords,
    getTotalPurchases,
    purchasesRecords,
    totalPurchases,
    totalProduction,
    getTotalProduction,
    getProductionRecords,
    productionRecords,
    totalInvestment,
    getTotalInvestment,
    investmentRecords,
    getInvestmentRecords,
    salesRecords,
    totalSales,
    getTotalSales,
    getSalesRecords,
    totalOperationCosts,
    getTotalOperationCosts,
    operationCostsRecords,
    getOperationCostsRecords,
    getTotalReceivables,
    totalReceivables,
    receivables,
    getReceivables,
    payables,
    getPayables,
    getTotalPayables,
    totalPayables,
    businessUsers,
    getBusinessUsers,
    getUserMetricDescription,
    getMyTotalSalesThisMonth,
    getMyTotalSalesThisWeek,
    getMyTotalSalesToday,
    myTotalSalesThisMonth,
    myTotalSalesThisWeek,
    myTotalSalesToday,
    myCustomersRecordedToday,
    myCustomersRecordedThisWeek,
    myCustomersRecordedThisMonth,
    getMyCustomersRecordedToday,
    getMyCustomersRecordedThisWeek,
    getMyCustomersRecordedThisMonth,
  }

  if (ready) {
    if (auth) {
      if (currentUserData) {
        return (
          <AppContext.Provider value={appContext}>
            <MainBody />
            <TopBar />
            <BottomBar />
            <Viewer />
            <SideViewer />
            <ConfirmDialog />
            <OverlayLoader />
            <BottomViewer />
            <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
          </AppContext.Provider>
        )
      } else {
        return (
          <AppContext.Provider value={appContext}>
            <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
            <MainLoader />
          </AppContext.Provider>
        )
      }
    } else {
      return (
        <AppContext.Provider value={appContext}>
          <OverlayLoader />
          <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
          <Login />
        </AppContext.Provider>
      )
    }
  } else {
    return (
      <AppContext.Provider value={appContext}>
        <ToastContainer style={{ zIndex: "var(--maxIndex)" }} position="bottom-right" />
        <MainLoader />
      </AppContext.Provider>
    )
  }
}